import {
  type ButtonProps,
  type PasswordInputProps,
  type TextInputProps,
  Button,
  HelperMessage,
  LoadingIcon,
  PasswordInput,
  Radio,
  TextInput,
} from '@iheartradio/web.companion';
import type { BaseConfig } from '@iheartradio/web.config';
import { isNotBlank } from '@iheartradio/web.utilities';
import { type ReactNode, useEffect, useState } from 'react';
import { useField, useIsSubmitting } from 'remix-validated-form';

type TextFieldProps = TextInputProps & {
  resetValue?: boolean;
  handlePasswordChange?: PasswordInputProps['handlePasswordChange'];
};

type GenderRadioGroupProps = {
  disabled?: boolean;
  error?: ReactNode;
  hint?: string;
  required?: boolean;
  defaultValue?: string;
  name: string;
  message?: ReactNode;
  genders: BaseConfig['account']['registration']['genders'];
  label?: string;
};

type SubmitButtonProps = {
  onClick?: ButtonProps['onClick'];
  label?: string;
  disabled?: ButtonProps['disabled'];
};

export function ErrorMessage(error: string) {
  return (
    <HelperMessage hasIcon kind="error">
      {error}
    </HelperMessage>
  );
}

export function TextField({
  name,
  label,
  placeholder,
  autoComplete,
  type,
  defaultValue,
  message,
  disabled = false,
  clearable = false,
  inputMode = 'text',
  hint,
  resetValue = false,
  ...rest
}: TextFieldProps) {
  const { error = message, getInputProps } = useField(name);
  const [value, setValue] = useState(defaultValue ?? '');

  useEffect(() => {
    if (resetValue) {
      setValue('');
    }
  }, [resetValue]);

  return (
    <TextInput
      {...getInputProps({ id: name, label, placeholder, type })}
      autoComplete={autoComplete ?? name}
      clearable={clearable}
      defaultValue={defaultValue ?? ''}
      disabled={disabled}
      hint={hint}
      inputMode={inputMode}
      isError={!!error}
      message={error ? ErrorMessage(error.toString()) : null}
      {...(disabled ? { value } : {})}
      {...rest}
    />
  );
}

export function PasswordField({
  message,
  name,
  label,
  placeholder,
  disabled = false,
  resetValue = false,
  handlePasswordChange,
  ...rest
}: TextFieldProps) {
  const { error, getInputProps } = useField(name);

  const messageComponent = (
    <>
      {error ? ErrorMessage(error) : null}
      {isNotBlank(message) && message}
    </>
  );

  const [value, setValue] = useState('');

  useEffect(() => {
    if (resetValue) {
      setValue('');
    }
  }, [resetValue]);

  return (
    <PasswordInput
      {...getInputProps({ id: name, label, placeholder })}
      autoComplete={name}
      disabled={disabled}
      handlePasswordChange={handlePasswordChange}
      isError={!!error}
      message={messageComponent}
      {...(disabled ? { value } : {})}
      {...rest}
    />
  );
}

export function GenderRadioGroup({
  defaultValue,
  disabled,
  hint,
  name,
  genders,
  required,
  label,
}: GenderRadioGroupProps) {
  const { error } = useField(name);

  const mappedGenders = Object.entries(genders ?? []).map(
    (entry: [string, { label: string }]) => {
      return {
        value: entry[0],
        label: entry[1].label,
      };
    },
  );

  return (
    <Radio.Group
      data-test="gender-radio-group"
      defaultValue={defaultValue}
      disabled={disabled}
      hint={hint}
      isError={!!error}
      label={label}
      message={error ? ErrorMessage(error) : null}
      name={name}
      orientation="horizontal"
      required={required}
    >
      {mappedGenders.map(option => {
        return (
          <Radio.Button
            data-test={`gender-${option.value}`}
            disabled={disabled}
            id={`gender-${option.value}`}
            key={option.label}
            value={option.value}
          >
            {option.label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
}

export const SubmitButton = ({
  label,
  disabled,
  onClick,
}: SubmitButtonProps) => {
  const isSubmitting = useIsSubmitting();

  return (
    <Button
      color="red"
      data-test="submit-button"
      disabled={disabled || isSubmitting}
      inline
      kind="primary"
      onClick={onClick}
      size="large"
      type="submit"
    >
      {isSubmitting ?
        <LoadingIcon fill="brand-white" />
      : label}
    </Button>
  );
};
